/* eslint-disable max-len */
import React from 'react'

import TshirtTable from './TshirtTable'
import { TablesContainer, SingleLine } from './styles'

import {
  biColor,
  biColorSpped,
  classique,
  fairtrade,
  classiqueSpped,
  fairtradeSpped,
} from '../data'

import { genTableData } from './helpers'

const Grille = () => (
  <>
    <TablesContainer>
      <TshirtTable
        title="T-shirts classiques"
        lines={genTableData(classique)}
      />
      <TshirtTable
        title="T-shirts fair trade et coton bio"
        lines={genTableData(fairtrade)}
      />
      <SingleLine twoLines>
        {`Impression recto + verso 1 couleur ou une face 2 couleurs : +${biColor}€/unité`}
      </SingleLine>
    </TablesContainer>

    <TablesContainer>
      <SingleLine $header twoLines>
        Impression rapide en deux semaines (selon les disponibilités)
      </SingleLine>
      <TshirtTable
        title="T-shirts classiques"
        lines={genTableData(classiqueSpped)}
      />
      <TshirtTable
        title="T-shirts fair trade et coton bio"
        lines={genTableData(fairtradeSpped)}
      />
      <SingleLine twoLines>
        {`Impression recto + verso 1 couleur ou une face 2 couleurs : +${biColorSpped}€/unité`}
      </SingleLine>
    </TablesContainer>
  </>
)

export default Grille
