import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../../Layout'
import SEO from '../../SEO'

import Tshirt from '../../components/Tshirt'

export const query = graphql`
  {
    file(relativePath: { eq: "print/home-serigraphy.jpg" }) {
      childImageSharp {
        resize {
          src
          width
          height
        }
      }
    }
  }
`

const TShirtPage = ({ data }) => (
  <Layout withPadding={false}>
    <SEO
      title="T-shirts"
      keywords={[
        't-shirt', 't-shirts', 'impression t-shirt', 'commande', 'serigraphie',
      ]}
      image={data.file.childImageSharp.resize}
    />
    <Tshirt />
  </Layout>
)

TShirtPage.propTypes = {
  data: shape({}).isRequired,
}

export default TShirtPage
