export const genTableData = table => table.reduce(
  (acc, { min, max, price }) => {
    const label = `${min} à ${max}`
    const priceLabel = typeof price === 'number'
      ? `${price}€/unité`
      : `${price.unity}€/unité + ${price.forfait}€`

    return [...acc, [label, priceLabel]]
  }, [],
)
