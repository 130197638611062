import React from 'react'
import { string, bool, func } from 'prop-types'

import StyledCheckbox, { Label, Span } from './styles'

const Checkbox = ({
  label, checked, onChange,
}) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <Label>
    <StyledCheckbox
      checked={checked}
      onChange={onChange}
    />
    <Span>{label}</Span>
  </Label>
)

Checkbox.propTypes = {
  label: string.isRequired,
  checked: bool.isRequired,
  onChange: func.isRequired,
}

export default Checkbox
