/* eslint-disable no-negated-condition */
/* eslint-disable no-nested-ternary */
import {
  biColor,
  biColorSpped,
  classique,
  fairtrade,
  classiqueSpped,
  fairtradeSpped,
} from './data'

const tableMatrix = {
  normal: [classique, fairtrade],
  speed: [classiqueSpped, fairtradeSpped],
}

export const getPrice = ({
  table, quantity, isBiColor, speed, biColorPrice, biColorSppedPrice,
}) => {
  const range = table.find(({ min, max }) => quantity >= min && quantity <= max)

  if (!range) return null
  const unityPrice = typeof range.price === 'number'
    ? range.price
    : range.price.unity
  const forfaitPrice = typeof range.price === 'number'
    ? 0
    : range.price.forfait
  const biColorUnityPrice = !isBiColor
    ? 0
    : speed ? biColorSppedPrice : biColorPrice

  const price =
    (unityPrice * quantity) + forfaitPrice + (biColorUnityPrice * quantity)

  return price
}

export const countTotal = ({
  quantity, isBiColor, speed, isfairTrade,
}) => {
  const matrixLine = speed ? 'speed' : 'normal'
  const matrixIndex = isfairTrade ? 1 : 0

  const table = tableMatrix[matrixLine][matrixIndex]
  const cleanQuantity = parseInt(quantity || '0', 10) || 0

  const price = getPrice({
    table,
    quantity: cleanQuantity,
    isBiColor,
    speed,
    biColorPrice: biColor,
    biColorSppedPrice: biColorSpped,
  })
  return price
}

export const validateQuantity = (quantity, min = 20, max = 200) => {
  if (quantity === '') return false

  const cleanQuantity = parseInt(quantity, 10)
  if (Number.isNaN(cleanQuantity)) return false

  return cleanQuantity >= min && cleanQuantity <= max
}

export const genDataForEmailDevi = ({
  quantity, isBiColor, speed, isfairTrade,
}) => {
  const data = [
    {
      label: 'Quantité',
      value: quantity,
    },
    {
      label: 'Recto + verso 1 couleur ou une face 2 couleurs',
      value: isBiColor,
    },
    {
      label: 'Impression rapide en 2 semaines',
      value: speed,
    },
    {
      label: 'T-shirt fair trade et coton bio',
      value: isfairTrade,
    },
  ]

  return data
}
