import React from 'react'
import { string, bool } from 'prop-types'
import styled, { css } from 'styled-components'

export const Label = styled.label`
  display: flex;
  align-items: flex-start;
`

export const Span = styled.span`
  position: relative;
  top: -3px;
  margin-left: 8px;
`

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  position: relative;
`

const Icon = styled.svg`
  fill: none;
  stroke: ${({ theme: { color } }) => color.primary.main};
  stroke-width: 2px;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const checked = ({ checked, theme: { color } }) => checked ?
  css`
    background-color: ${color.secondary.dark};
  `
  : css`
    background-color: transparent;
    border: 1px solid ${color.text};
  `

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  ${checked};
  transition: all 150ms;

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`

const Checkbox = ({
  className, checked, ...props
}) => (
  <CheckboxContainer className={className}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)

Checkbox.defaultProps = {
  className: '',
}

Checkbox.propTypes = {
  className: string,
  checked: bool.isRequired,
}

export default Checkbox
